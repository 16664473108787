/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Kickstart
 * @author     Christopher Orth <christopher.orth@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

/**
 * call necessary methods
 */
Dotfly.init();
Navigation.init();
BlogContentModule.init();