/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   jQuery Plugin
 * @author     Christopher Orth <christopher.orth@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */
(function( $ ){

    /**
     * dot formfields method
     *
     * @return void
     */
    $.fn.dotFormfields = function() {

        var _sFocusClass = 'focus';

        this.focus(function(){
            var _oSelf           = $(this);
            var _sFormFieldValue = _oSelf.val();
            var _sFormFieldTitle = _oSelf.attr('title');

            _oSelf.addClass(_sFocusClass);
            _oSelf.addClass('edit');
            _oSelf.blur(function(){
                _oSelf.removeClass(_sFocusClass);

                if ( $(this).val() == '' ){
                    _oSelf.removeClass('edit');
                }

            });

            if ( _sFormFieldValue == _sFormFieldTitle )
            {
                _oSelf.val('');
                _oSelf.blur(function(){
                    var sNewValue = _oSelf.val();
                    sNewValue == '' ? _oSelf.val(_sFormFieldTitle) : _oSelf.val(sNewValue);
                });
            }
        });

    };

})( jQuery );