/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Kickstart
 * @author     Sascha Dörrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */
var Dotfly = {

    /**
     * init/constructor method
     *
     * @return void
     */
    init : function()
    {
        this.instant();
        this.domReady();
        this.onLoad();
    },

    /**
     * Binding for events at interpreting
     *
     * @return void
     */
    instant : function(){
        //Internet Explorer: Eat This!
        if(!Array.indexOf)
        {
            Array.prototype.indexOf = function(obj)
            {
                for(var i=0; i<this.length; i++)
                {
                    if(this[i]==obj)
                    {
                        return i;
                    }
                }
                return -1;
            }
        }

        /**
         * Number.prototype.format(n, x, s, c)
         *
         * @param {number} n length of decimal
         * @param {number} x length of whole part
         * @param {any}   s sections delimiter
         * @param {any}   c decimal delimiter
         */
        Number.prototype.format = function (n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));

            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        };
    },

    /**
     * Binding for domReady-Event
     *
     * @return void
     */
    domReady : function()
    {
        oDotfly = this;

        $(document).ready(function() {
            oDotfly.addJsClass();
        });

        $.fn.iniLazyloading = function() {
            window.lazySizesConfig = window.lazySizesConfig || {};
            document.addEventListener('lazybeforeunveil', function(e){
                $(e.target).parent('.e-placeholder').removeClass('is--spin');
            });
        };

        var _slider = $('.c-slider');

        _slider.on("init", function(){
            if ($(window).width() <= 640) {
                $(".slick-arrow").css("margin-top", -($(".outer-container").first().height() / 2 + 60));
            }
        });

        _slider.slick({
            infinite: true,
            dots: true,
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 7000,
            speed: 1200
        })

        $(window).on("resize", function(){
            if ($(window).width() <= 640) {
                $(".slick-arrow").css("margin-top", -($(".outer-container").first().height() / 2 + 60));
            }else {
                $(".slick-arrow").css("margin-top", -60);
            }
        });

        if (document.cookie.indexOf("show_warning") === -1) {
            $(".c-cookie__holder").show();
            $(".c-cookie__dismiss").on("click", function(){
                $(".c-cookie__holder").hide();
                var d = new Date();
                d.setTime(d.getTime() + (30*24*60*60*1000));
                document.cookie = "show_warning=false;expires="+ d.toUTCString() + ";path=/";
            });
        }
    },

    /**
     * Binding for onLoad-Event
     *
     * @return void
     */
    onLoad : function(){
        var _oDragging = false;
        $('body').on('touchmove', function(){
            _oDragging = true;
        });

        $(window).load(function(){
            "use strict";

        });

        $(window).resize(function(){
            "use strict";

        });
    },

    /**
     * add js class
     *
     * @return void
     */
    addJsClass : function()
    {
        $('html').addClass('js');
    }

};
