/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Content Module
 * @author     Dustin Breuer <dustin.breuer@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */
var BlogContentModule = {

    /**
     * init/constructor method
     *
     * @return void
     */
    init : function()
    {
        this.domReady();
        this.onResize();
    },

    /**
     * Binding for domReady-Event
     *
     * @return void
     */
    domReady : function()
    {
        var _oMe = this;
        var bIsDesktop = (jQuery(window).width() >= 960);

        var oRoyalSliderConfig = window.oRoyalSliderConfig || {};

        if(!bIsDesktop){
            oRoyalSliderConfig = $.extend(true, oRoyalSliderConfig, {
                fullscreen : {
                    keyboardNav : false
                },
                controlNavigation : 'bullets',
                arrowsNav : false,
                thumbs : null,
                imageScaleMode : 'fill',
                // autoScaleSliderWidth : '100%',
                imageScalePadding : 0,
                arrowsNavHideOnTouch: false
            });
        }

        $(document).ready(function() {
            _oMe.initAccordions();
        });

        $(window).load(function(){
            window.dotRoyalSliders = $('.royal-slider').removeClass('rs-not-ready').royalSlider(oRoyalSliderConfig);
        });
    },

    /**
     * Binding for domReady-Event
     *
     * @return void
     */
    onResize : function()
    {
        $(window).resize(function(){

        });
    },

    initAccordions : function(){
        var _oAccordions = $('.content-module-block.accordion');

        _oAccordions.on('click', '.accordion-toggler', function(oEvent){
            oEvent.preventDefault();

            var _oToggler = $(this),
                _oAccordionItem = _oToggler.closest('.accordion-item'),
                _oAccordionContent = _oAccordionItem.find('.accordion-item-content');

            if(_oAccordionItem.is('.open')){
                _oAccordionItem.removeClass('open').addClass('close');
                _oAccordionContent.slideUp(300);
            } else {
                _oAccordionItem.removeClass('close').addClass('open');
                _oAccordionContent.slideDown(500);
            }
        }).find('.accordion-item').addClass('close').find('.accordion-item-content').hide();
    }
};
