/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Kickstart
 * @author     Sascha Dörrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */
var Navigation = {

    iLastCheckedWidth : 0,

    /**
     * init/constructor method
     *
     * @return void
     */
    init : function()
    {
        this.domReady();
        this.onResize();
    },

    /**
     * Binding for domReady-Event
     *
     * @return void
     */
    domReady : function()
    {
        oNavigation = this;

        $(document).ready(function() {
            oNavigation.initSearch();
            oNavigation.initOffCanvas();
            oNavigation.initMobileSubmenus();

            $('.no-hover > a').on('click.dotfly', function(oEvent){
                "use strict";

                oEvent.preventDefault();
            });
        });
    },

    /**
     * Binding for domReady-Event
     *
     * @return void
     */
    onResize : function()
    {
        $(window).resize(function(){
            oNavigation.moveNavigation();
        }).resize();
    },

    initSearch : function()
    {
        "use strict";

        $('.search-toggler').on('click.dotfly', function (oEvent) {
            var _oSearchForm      = $('.search-form'),
                _iSearchFormWidth = _oSearchForm.width();

            _oSearchForm.css({
                width: 0,
                display: 'block'
            }).animate({
                width: _iSearchFormWidth
            }, 275, function(){
                _oSearchForm.find('.search-field').focus();
            });

            oEvent.preventDefault();
        });
    },

    moveNavigation : function()
    {
        "use strict";

        var _oTopNav = $('#TopNav');
        var _oMainNav = $('#MainNav');
        var _oNavigation = $('#Navigation');
        var _oSearchItem = $('.search-toggler-item');

        if($(window).width() > 960){
            // Desktop (restore)
            _oTopNav.insertBefore(_oMainNav);
            _oTopNav.find('.nav-menu').prepend(_oSearchItem);
        } else {
            // Mobile
            _oTopNav.appendTo(_oMainNav);
            _oNavigation.prepend(_oSearchItem);
        }

    },

    initOffCanvas : function()
    {
        "use strict";

        var _oMenuItems = $('#Navigation').find('.menu-item');

        $('#OffCanvasToggle').on('click.dotfly', function(oEvent){
            oEvent.preventDefault();

            $('html').toggleClass('menu-open');
            _oMenuItems.removeClass('show-sub');
        });
    },

    initMobileSubmenus : function()
    {
        "use strict";

        $('#Navigation').on('click.dotfly', '.menu-item', function(oEvent){
            var _oListItem = $(oEvent.currentTarget);

            if($(window).width() < 960){
                if(!_oListItem.hasClass('show-sub')){
                    oEvent.preventDefault();
                    _oListItem.addClass('show-sub')
                }
            }

        });
    }

};
